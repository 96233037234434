<template>
    <div class="index-page">
        <div class="title">
            {{ merchantInfo.merchants_name }}
        </div>
        <!-- <div class="top-wrapper">
            <div class="top-data">
                <div class="left-info">
                    <div class="time">
                        <span>{{
                            new Date() | datefmt("YYYY-MM-DD HH:mm:ss")
                        }}</span>
                        <span style="padding-left: 15px">星期三</span>
                    </div>
                    <span class="tip-text"
                        >Hi，{{ getTimeState() }}欢迎使用飞鱼商户管理系统</span
                    >
                </div>
            </div>
            <div class="flowing-water-container">
                <div class="flowing-water-title">今日流水记录</div>

                <div class="flowing-water-content">
                    <div class="item">
                        <span class="item-title">今日订单数</span>
                        <span class="num">{{ saleData.count || 0 }}</span>
                    </div>
                    <div class="item">
                        <span class="item-title">今日收款金额</span>
                        <span class="num">
                            <span style="font-size: 16px; font-weight: 600"
                                >¥</span
                            >{{
                                saleData.pay_amount
                                    ? (saleData.pay_amount / 100).toFixed(2)
                                    : 0
                            }}
                            <span style="font-size: 16px; font-weight: 600"
                                >元</span
                            >
                        </span>
                    </div>
                    <div class="item">
                        <span class="item-title">今日退单数</span>
                        <span class="num">{{
                            saleData.refund_count || 0
                        }}</span>
                    </div>
                    <div class="item">
                        <span class="item-title">今日退款金额</span>
                        <span class="num">
                            <span style="font-size: 16px; font-weight: 600"
                                >¥</span
                            >{{
                                saleData.refund_amount
                                    ? (saleData.refund_amount / 100).toFixed(2)
                                    : 0
                            }}
                            <span style="font-size: 16px; font-weight: 600"
                                >元</span
                            >
                        </span>
                    </div>
                </div>
            </div>
            <div class="flowing-water-container" style="margin-top: 20px">
                <div class="flowing-water-title">本月流水记录</div>

                <div class="flowing-water-content">
                    <div class="item">
                        <span class="item-title">本月订单数</span>
                        <span class="num">{{ saleMonthData.count || 0 }}</span>
                    </div>
                    <div class="item">
                        <span class="item-title">本月收款金额</span>
                        <span class="num">
                            <span style="font-size: 16px; font-weight: 600"
                                >¥</span
                            >{{
                                saleMonthData.pay_amount
                                    ? (saleMonthData.pay_amount / 100).toFixed(
                                          2
                                      )
                                    : 0
                            }}
                            <span style="font-size: 16px; font-weight: 600"
                                >元</span
                            >
                        </span>
                    </div>
                    <div class="item">
                        <span class="item-title">本月退单数</span>
                        <span class="num">{{
                            saleMonthData.refund_count || 0
                        }}</span>
                    </div>
                    <div class="item">
                        <span class="item-title">本月退款金额</span>
                        <span class="num">
                            <span style="font-size: 16px; font-weight: 600"
                                >¥</span
                            >{{
                                saleMonthData.refund_amount
                                    ? (
                                          saleMonthData.refund_amount / 100
                                      ).toFixed(2)
                                    : 0
                            }}
                            <span style="font-size: 16px; font-weight: 600"
                                >元</span
                            >
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
        <hr /> -->
        <div class="top-wrapper">
            <span class="tip-text3"
                >请您选择要查询的起始日期及时间（如未选择，默认显示近7天数据）</span
            >
            <div class="block">
                <el-date-picker
                    v-model="value1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="updateTime"
                    :default-time="['23:59:59', '23:59:59']"
                >
                </el-date-picker>
                <el-button type="success" @click="submit">提交</el-button>
            </div>
            <div class="top-data2">
                <div class="left-info2">
                    <span class="tip-text2"
                        >商家名称：{{ getStatistical.merchants_name }}</span
                    >
                </div>
            </div>
            <div class="flowing-water-container">
                <div class="flowing-water-title"></div>
                <div class="flowing-water-content">
                    <div class="item2">
                        <span class="item-title2">营业额</span>
                        <span class="num2">
                            {{ formattedValue(getStatistical.turnover) || 0 }}
                        </span>
                    </div>
                    <div class="item2">
                        <span class="item-title2">有效营业额</span>
                        <span class="num2">
                            {{
                                formattedValue(
                                    getStatistical.effective_sales
                                ) || 0
                            }}
                        </span>
                    </div>
                    <div class="item2">
                        <span class="item-title2">总订单</span>
                        <span class="num2">
                            {{ getStatistical.total_order || 0 }}
                            <span style="font-size: 16px; font-weight: 500"
                                >单</span
                            ></span
                        >
                    </div>
                    <div class="item2">
                        <span class="item-title2">有效订单</span>
                        <span class="num2">
                            {{ getStatistical.effective_order || 0 }}
                            <span style="font-size: 16px; font-weight: 500"
                                >单</span
                            >
                        </span>
                    </div>
                    
                </div>
            </div>
            <div class="flowing-water-container" style="margin-top: 20px">
                <div class="flowing-water-title"></div>
                <div class="flowing-water-content">
                    <div class="item2">
                        <span class="item-title2">退款</span>
                        <span class="num2">
                            {{ formattedValue(getStatistical.a_refund) || 0 }}
                        </span>
                    </div>
                    <div class="item2">
                        <span class="item-title2">退款订单数量</span>
                        <span class="num2">
                            {{ getStatistical.a_refund_orders || 0 }}
                            <span style="font-size: 16px; font-weight: 500"
                                >单</span
                            ></span
                        >
                    </div>
                    <!-- <div class="item2">
                        <span class="item-title2">配送费</span>
                        <span class="num2">
                            {{
                                formattedValue(getStatistical.shipping_fee) || 0
                            }}
                        </span>
                    </div> -->
                    <div class="item2">
                        <span class="item-title2">平台收取</span>
                        <span class="num2">
                            {{ formattedValue(getStatistical.platform) || 0 }}
                        </span>
                    </div>
                    <div class="item2">
                        <span class="item-title2">商家实收</span>
                        <span class="num2">
                            {{
                                formattedValue(
                                    getStatistical.merchants_paid_in
                                ) || 0
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
let now = new Date();
let realnow = new Date();
now.setHours(23);
now.setMinutes(59);
now.setSeconds(59);
export default {
    name: "Printer",
    data() {
        return {
            saleData: {},
            saleMonthData: {},
            merchantInfo: {},
            statistical: {},
            getStatistical: {},
            start_time: "",
            end_time: "",
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            value1: [now - 3600 * 1000 * 24 * 7, realnow],
            fixedTime: now,
            // value1: [new Date() - 3600 * 1000 * 24 * 7, new Date()],
            value2: "",
        };
    },
    mounted() {
        this.merchantInfo = JSON.parse(localStorage.getItem("merchantInfo"));
        this.getData();
        this.getMonthData();
        // this.lastweek();
    },

    methods: {
        // 挂载即获取7天内
        lastweek() {
            // 以下这两行是防止页面已经挂载加载出来7天数据，用户又再次点击提交会弹出未选择时间
            this.start_time =
                moment(this.value1[0]).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
            this.end_time =
                moment(this.value1[1]).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
            this.axios
                .post("/order/statistical", {
                    start_time:
                        moment(this.value1[0]).format(
                            "YYYY-MM-DDTHH:mm:ss.SSS"
                        ) + "Z",
                    end_time:
                        moment(this.value1[1]).format(
                            "YYYY-MM-DDTHH:mm:ss.SSS"
                        ) + "Z",
                })
                .then((response) => {
                    this.getStatistical = response.data;
                    this.$forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 时间处理器
        updateTime(value) {
            if (value && value.length === 2) {
                const startTime =
                    moment(value[0]).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
                this.start_time = startTime;
                const endTime =
                    moment(value[1]).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
                this.end_time = endTime;
                this.$forceUpdate();
                // console.log(this.start_time, this.end_time); // 输出转换后的时间格式
            }
        },
        submit() {
            this.axios
                .post("/order/statistical", {
                    start_time: this.start_time,
                    end_time: this.end_time,
                })
                .then((response) => {
                    // console.log("请求成功");
                    // console.log(response.data);
                    this.getStatistical = response.data;
                    this.$forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getData() {
            this.axios.post("/order/one/day/the/water").then((res) => {
                // console.log(res.data);
                this.saleData = res.data;
                // console.log(this.saleData);
            });
        },
        getMonthData() {
            this.axios.post("/order/during/month").then((res) => {
                // console.log(res.data);
                this.saleMonthData = res.data;
            });
        },
        getTimeState() {
            // 获取当前时间
            let timeNow = new Date();
            // 获取当前小时
            let hours = timeNow.getHours();
            // 设置默认文字
            let state = ``;
            // 判断当前时间段
            if (hours >= 0 && hours <= 10) {
                state = `早上好！`;
            } else if (hours > 10 && hours <= 14) {
                state = `中午好！`;
            } else if (hours > 14 && hours <= 18) {
                state = `下午好！`;
            } else if (hours > 18 && hours <= 24) {
                state = `晚上好！`;
            }
            return state;
        },
    },
    computed: {
        // 金额处理器
        formattedValue() {
            return function (value) {
                value = value / 100 || 0;
                return value.toLocaleString("zh-CN", {
                    style: "currency",
                    currency: "CNY",
                });
            };
        },
    },
};
</script>

<style lang="scss">
.index-page {
    .title {
        padding: 14px;
        // margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .top-wrapper {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px 30px;
        box-sizing: border-box;
        .tip-text3 {
            font-weight: 600;
            font-size: 20px;
            margin-top: 20px;
            user-select: none;
        }
        .block {
            margin-top: 20px;
            // margin-left: 100px;
        }
        .flowing-water-container {
            .flowing-water-title {
                margin: 20px 0;
                font-size: 20px;
                font-weight: 600;
            }

            .flowing-water-content {
                display: flex;
                .item {
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    padding: 0 30px;
                    border-right: 1px solid #e9e9e9;

                    .item-title {
                        color: rgba(0, 0, 0, 0.65);
                        font-size: 14px;
                    }
                    .num {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 30px;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
                .item2 {
                    width: 70px;
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0 30px;
                    border-right: 1px solid #e9e9e9;
                    background-color: #fff;
                    user-select: none;

                    .item-title2 {
                        text-align: center;
                        white-space: nowrap;
                        color: rgba(0, 0, 0, 0.65);
                        font-size: 18px;
                        font-weight: 600;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    .num2 {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 18px;
                        font-family: "Gill Sans", "Gill Sans MT", Calibri,
                            "Trebuchet MS", sans-serif;
                        white-space: nowrap;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        .top-data {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            position: relative;
            .left-info {
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .time {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.45);
                    line-height: 22px;
                }
            }
        }
        .top-data2 {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            height: 50px;
            .left-info2 {
                margin-top: 50px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .time {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.45);
                    line-height: 22px;
                }
            }
        }
        .tip-text2 {
            font-weight: 600;
            font-size: 20px;
            user-select: none;
        }
    }
}
</style>
